import React, { useState, useEffect } from "react";
import LocationIcon from "assets/icons/location.svg";
import { useSelector, useDispatch } from "react-redux";
import { selectCurrentStore, getCommonDetails } from "redux/actions";
import StoreLocationModal from "./StoreLocationModal";
import { getLocalStorageValue, setLocalStorageValue } from "config/helper";

const StoreLocation = ({ onMobileSidebarClose }) => {
  const dispatch = useDispatch();
  const { storeListData, currentStoreID } = useSelector(({ store }) => store);
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const clientId = getLocalStorageValue("RSAclient-id");
  const aogEndPointURL = getLocalStorageValue("api-end-point-aog");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const initialStore = getLocalStorageValue("selected-initial-store-id");
  // console.log(initialStore)
  const [isShowDeliveryPopup, setIsDeliveryPopup] = useState(false);

  const selectedStore =
    storeListData &&
    storeListData.find(
      (store) =>
        store.ClientStoreId === getLocalStorageValue("selected-store-id")
    );

  const prevLocation = getLocalStorageValue("selected-store-id");
  // console.log(prevLocation);

  //date:1 feb 2021, token no:1571, requirement:Display the popup-select location, If client is visiting the website for the first time
  useEffect(() => {
    if (!initialStore) setIsDeliveryPopup(true);
    // Madhu - 2023/02/17 - error : we cant access ClientStoreId from storeListData. - Line No : 33-41
    if (storeListData.length === 1) {
      setLocalStorageValue("selected-store-id", storeListData[0].ClientStoreId);
      setLocalStorageValue(
        "selected-initial-store-id",
        storeListData[0].ClientStoreId
      );
      setIsDeliveryPopup(false);
    }
  }, []);

  useEffect(() => {
    if (
      !getLocalStorageValue("selected-store-id") &&
      !currentStoreID &&
      !!storeListData.length
    ) {
      dispatch(selectCurrentStore(storeListData[0]));
      setLocalStorageValue("selected-store-id", storeListData[0].ClientStoreId);

      if (clientId && aogEndPointURL) {
        const body = {
          RSAClientId: clientId,
          ClientStoreId: storeListData[0].ClientStoreId || 1,
          AppName: isUserLoggedIn ? "shop" : "",
          member_number: isUserLoggedIn ? memberNumber : "",
          user_token: isUserLoggedIn ? userToken : "",
          Version: isUserLoggedIn ? "1" : "",
          DeviceType: isUserLoggedIn ? "web" : "",
        };
        dispatch(getCommonDetails(body));
      }
    }
  }, [localStorage, currentStoreID, storeListData]); // eslint-disable-line

  return (
    <>
      <div className="store-location" onClick={() => setIsDeliveryPopup(true)}>
    <span className="shop-lable">Shopping at</span>
        <div className="location-store">
          <img src={LocationIcon} alt="LocationIcon" />
          <span>
            {selectedStore ? selectedStore.ClientStoreName : "Select Store"}
          </span>
        </div>
      </div>
      <StoreLocationModal
        isShowDeliveryPopup={isShowDeliveryPopup}
        onClose={() => {
          (!!getLocalStorageValue("selected-store-id") || currentStoreID) &&
            setIsDeliveryPopup(false);
          onMobileSidebarClose();
        }}
        selectedStore={selectedStore}
      />
    </>
  );
};

export default StoreLocation;
