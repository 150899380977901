import React, { useState, useEffect } from "react";
import './catering-radio-group.scss'
import { useDispatch } from 'react-redux';
import DownIcon from "assets/icons/bold-down-arrow.svg";
import {
    handleUpdateCateringAndMealCartCustomOption
} from "redux/actions";

const CateringRadioGroup = ({
    groupData,
    custom_options_arr,
    productIndex,
    setShowProductButton
}) => {
    const dispatch = useDispatch()
    const { values, title, is_required, title: label, is_add_option_price, id: groupId } = groupData

    const [selectedOption, setSelectedOption] = useState();

    useEffect(() => {

        const defaultSelectedOption = custom_options_arr.find(option => option.option_id === groupId)
        defaultSelectedOption && setSelectedOption(defaultSelectedOption.value)

    }, [custom_options_arr]) // eslint-disable-line


    const handleChangeOption = (optionValue, option) => {
        const { id, option_id, price, value } = option
        setSelectedOption(optionValue)
        dispatch(handleUpdateCateringAndMealCartCustomOption({ [groupId]: [{ id, is_add_option_price, label, option_id, price, value }] }, productIndex, true))
        setShowProductButton()
    }

     const [isOpen, setIsOpen] = useState(false);
    
        const toggleMenu = () => {
            setIsOpen(!isOpen);
        };
    

    return (
        <div className="catering-radio-group fdf">
            <p onClick={toggleMenu}><strong>{title}</strong>{is_required === "1" && <span>*</span>}<div className={isOpen ? "arrow arrow-up" : "arrow arrow-down"}>
                <img src={DownIcon} alt="downIcon" />
            </div></p>
            <div className={isOpen ? "check-box-wrapper dropdown-show" : "check-box-wrapper dropdown-hide"}>
            <div className="catering-radio-group-item-list">
                {values && values.length && values.map((option => {
                    return <div className="catering-item-with-radio" key={option.id}>
                        <input
                            type="radio"
                            id={option.id + productIndex}
                            onChange={() => handleChangeOption(option.value, option)}
                            checked={option.value === selectedOption}
                        />
                        <label htmlFor={option.id + productIndex}>
                            <span>{option.value} {option.price !== "0.00" ? `- $${option.price}` : ""}</span>
                        </label>
                    </div>
                }))}
            </div>
            </div>
        </div>
    );
};

export default CateringRadioGroup;
