import React, { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import { userRegister, clearRegisterMessage } from "redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Breadcrumb from "components/Common/Breadcrumb";
import "./signup.scss";
import { getLocalStorageValue } from "config/helper";
import { head } from "lodash";

const SignupPage = () => {
  const recaptchaRef = React.createRef();
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const clientId = getLocalStorageValue("RSAclient-id");
  const client_name = getLocalStorageValue("client-name");
  const storeList = useSelector(({ store }) => store.storeListData);

  const { registerStatus, registerMessage } = useSelector(({ user }) => user);

  const thirdPartyLogin = useSelector(
    ({ common }) => common.commonDetailsData.third_party_login_section
  );

  const headerSection = useSelector(
    ({ common }) => common.commonDetailsData.header_section
  );

  const icontactOptions = useSelector(
    ({ common }) => common.commonDetailsData?.icontact_stay_connected_option
  );

  const [selectedStore, setSelectedStore] = useState(storeId);
  const [recaptchaValue, setRecaptchaValue] = useState("");
  const [captchaError, setCaptchaError] = useState("");
  const handleSelectStore = (event) => {
    setSelectedStore(event.target.value);
  };

  const {
    allow_link_card_registration,
    member_card_max_length,
    member_card_min_length,
    loyalty_card_message,
    is_numeric_password,
    numeric_password_length,
    complex_password_max_length,
    complex_password_min_length,
    your_recaptcha_site_key
  } = headerSection;

  const {
    allow_apple_login,
    allow_facebook_login,
    allow_google_login,
    allow_linkcard_login
  } = thirdPartyLogin;

  const isShowThirdPartyLoginSection =
    Object.values(thirdPartyLogin).includes("1");

  const { register, handleSubmit, watch, errors } = useForm({});

  const dispatch = useDispatch();
  // ticket no :1171 Displaying required message for terms and captcha on 2/22/2023 - Navina

  const onChangeReCaptcha = (value) => {
    setCaptchaError("");
    setRecaptchaValue(value);
  };

  useEffect(() => {
    return () => {
      dispatch(clearRegisterMessage());
    };
  }, [dispatch]);

  if (registerStatus === "success") {
    window.location.href = "/login";
  }
  // RequireField - Asterisk formatting - 21/02/2023 - Navina
  const breadcrumbList = [{ lable: "Sign Up", link: "" }];

  const handleSubmitRegisterForm = (data, e) => {
    //console.log("data", data);
    const {
      firstName,
      lastName,
      zipCode,
      email,
      password,
      confirmPassword,
      mobile,
      loyaltyNumber,
      confirmLoyaltyNumber,
      icontact_selected_value
    } = data;
    const body = {
      RSAClientId: clientId,
      ClientStoreId: selectedStore || 1,
      first_name: firstName,
      last_name: lastName,
      email_id: email,
      password: password,
      zipcode: zipCode,
      mobile: mobile,
      member_number: loyaltyNumber ? loyaltyNumber : "",
      icontact_selected_value: icontact_selected_value
    };
    if (recaptchaValue) {
      loyaltyNumber
        ? loyaltyNumber === confirmLoyaltyNumber &&
        password === confirmPassword &&
        dispatch(userRegister(body))
        : password === confirmPassword && dispatch(userRegister(body));
      e.target.reset();
    } else {
      setCaptchaError("The captcha field is required");
    }
  };

  return (
    <>
      {/* <Breadcrumb breadcrumbList={breadcrumbList} /> */}
      <BreadcrumbDetailed breadcrumbList={breadcrumbList} title={`Sign Up`} />

      <div className="container">
        <div className="signup-page-wrapper">
          <div className="auth-grid">
            <div className="auth-grid-items">
              <h1>Create an account</h1>
              <p>
                Already have an account?{" "}
                <span>
                  <Link to="/login">Sign In</Link>
                </span>
              </p>
              {registerStatus ? (
                <span
                  className={
                    registerStatus === "success"
                      ? "register-success"
                      : "register-error"
                  }>
                  {registerStatus === "success" ? (
                    <Link to="/login">
                      You have been registered successfully. Please
                      <span> Sign In </span>
                      to your account
                    </Link>
                  ) : (
                    `Failed to register - ${registerMessage}`
                  )}
                </span>
              ) : (
                ""
              )}
              <form
                name="register"
                onSubmit={handleSubmit(handleSubmitRegisterForm)}>
                <div className="name-wrapper">
                  <div className="form-control">
                    <label>First Name *</label>
                    <input
                      type="text"
                      name="firstName"
                      id="firstName"
                      ref={register({
                        required: true,
                        pattern: /^[A-Z0-9a-z_-\s]+$/
                      })}
                    />
                    {errors.firstName &&
                      errors.firstName.type === "required" && (
                        <span>Please enter your First Name</span>
                      )}
                    {errors.firstName &&
                      errors.firstName.type === "pattern" && (
                        <span>Please enter valid First Name</span>
                      )}
                  </div>
                  <div className="form-control">
                    <label>Last Name *</label>
                    <input
                      type="text"
                      name="lastName"
                      id="lastName"
                      ref={register({
                        required: true,
                        pattern: /^[A-Z0-9a-z_-\s]+$/
                      })}
                    />
                    {errors.lastName && errors.lastName.type === "required" && (
                      <span>Please enter your Last Name</span>
                    )}
                    {errors.lastName && errors.lastName.type === "pattern" && (
                      <span>Please enter valid Last Name</span>
                    )}
                  </div>
                </div>

                <div className="zip-code-wrapper">
                  <div className="form-control">
                    <label>Zip Code (5 digits) *</label>
                    <input
                      name="zipCode"
                      id="zipCode"
                      type="text"
                      maxLength={5}
                      ref={register({
                        required: true,
                        pattern: /^[0-9\b]{5}$/
                      })}
                    />

                    {errors.zipCode && errors.zipCode.type === "required" && (
                      <span>Please enter your Zip Code</span>
                    )}
                    {errors.zipCode && errors.zipCode.type === "pattern" && (
                      <span>Please enter valid zip code</span>
                    )}
                  </div>
                  <div className="form-control store-drop-down">
                    <label>Preferred Store *</label>
                    <select
                      value={selectedStore}
                      name="selectStore"
                      onChange={handleSelectStore}
                      className="form-control show-items dropdown-list-align select-dropdown"
                      ref={register({
                        required: true
                      })}>
                      {storeList.length > 0 &&
                        storeList.map((store) => {
                          return (
                            <option value={store.ClientStoreId}>
                              {store.ClientStoreName}
                            </option>
                          );
                        })}
                    </select>

                    {errors.selectStore &&
                      errors.selectStore.type === "required" && (
                        <span>Please Select Store.</span>
                      )}
                  </div>
                </div>
                <br />
                <br />
                <div className="form-control">
                  <label>Phone Number (10 digits) </label>
                  <input
                    name="mobile"
                    id="mobile"
                    type="text"
                    maxLength={10}
                    ref={register({
                      required: false,
                      pattern: /^[0-9\b]{10}$/
                    })}
                  />

                  {errors.mobile && errors.mobile.type === "required" && (
                    <span>Please enter your phone number</span>
                  )}
                  {errors.mobile && errors.mobile.type === "pattern" && (
                    <span>Please enter valid phone number</span>
                  )}
                </div>
                <div className="form-control">
                  <label>Email Address *</label>
                  <input
                    name="email"
                    id="email"
                    ref={register({
                      required: true,
                      pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
                    })}
                  />
                  {errors.email && errors.email.type === "required" && (
                    <span>Please enter email.</span>
                  )}
                  {errors.email && errors.email.type === "pattern" && (
                    <span>
                      The email address entered is invalid, please check the
                      formatting (e.g. email@domain.com)
                    </span>
                  )}
                </div>
                <div className="form-control">
                  {is_numeric_password === "1" ? (
                    <div className="form-control">
                      <label>Pin * ({numeric_password_length} Digits)</label>
                      <input
                        name="password"
                        id="password"
                        type="password"
                        maxLength={
                          is_numeric_password === "1"
                            ? numeric_password_length
                            : complex_password_max_length
                        }
                        minLength={
                          is_numeric_password === "1"
                            ? numeric_password_length
                            : complex_password_min_length
                        }
                        ref={register({
                          required: true,
                          minLength: +numeric_password_length,
                          maxLength: +numeric_password_length,
                          pattern: /^[0-9]+$/ // eslint-disable-line
                        })}
                      />

                      {errors.password &&
                        errors.password.type === "required" && (
                          <span>Please enter Pin.</span>
                        )}
                      {errors.password &&
                        errors.password.type === "minLength" && (
                          <span>
                            Pin must only {numeric_password_length} digits.
                          </span>
                        )}
                      {errors.password &&
                        errors.password.type === "maxLength" && (
                          <span>
                            Pin must only {numeric_password_length} digits.
                          </span>
                        )}
                      {errors.password &&
                        errors.password.type === "pattern" && (
                          <span>Pin must contain only number.</span>
                        )}
                    </div>
                  ) : (
                    <div className="form-control">
                      <label>
                        Password *
                        {/* <span className="form-control-password">?</span>
                        <span className="form-control-validation">
                          {complex_password_min_length +
                            -+complex_password_max_length}{" "}
                          character long, 1 Capital letter, 1 small letter,{" "}
                          <br></br>1 special character, 1 numeric required.
                        </span> */}
                        <span style={{ color: "grey" }}>
                          {" "}
                          {/*password validation- text added*/}
                          {"(" +
                            complex_password_min_length +
                            -+complex_password_max_length}{" "}
                          characters long. 1 Capital letter, 1 small letter, 1
                          Special character, 1 numeric required.)
                        </span>
                      </label>
                      <input
                        name="password"
                        id="password"
                        type="password"
                        maxLength={
                          is_numeric_password === "1"
                            ? numeric_password_length
                            : complex_password_max_length
                        }
                        minLength={
                          is_numeric_password === "1"
                            ? numeric_password_length
                            : complex_password_min_length
                        }
                        ref={register({
                          required: true,
                          minLength: +complex_password_min_length,
                          maxLength: +complex_password_max_length,
                          pattern:
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/ // eslint-disable-line
                        })}
                      />
                      {errors.password &&
                        errors.password.type === "required" && (
                          <span>Please enter Password.</span>
                        )}
                      {errors.password && errors.password.type === "minLength" && (
                        <span>
                          Password must only {complex_password_min_length} -{" "}
                          {complex_password_max_length} characters.
                        </span>
                      )}
                      {errors.password && errors.password.type === "maxLength" && (
                        <span>
                          Password must only {complex_password_min_length} -{" "}
                          {complex_password_max_length} characters.
                        </span>
                      )}
                      {errors.password &&
                        errors.password.type === "pattern" && (
                          <span>
                            {complex_password_min_length +
                              -+complex_password_max_length}{" "}
                            characters long. 1 Capital letter, 1 small letter, 1
                            Special character, 1 numeric required.
                          </span>
                        )}
                    </div>
                  )}

                  <div className="form-control">
                    {is_numeric_password === "1" ? (
                      <label>Re-enter Pin *</label>
                    ) : (
                      <label>Re-enter Password *</label>
                    )}

                    <input
                      name="confirmPassword"
                      id="confirmPassword"
                      type="password"
                      maxLength={
                        is_numeric_password === "1"
                          ? numeric_password_length
                          : complex_password_max_length
                      }
                      minLength={
                        is_numeric_password === "1"
                          ? numeric_password_length
                          : complex_password_min_length
                      }
                      ref={register({
                        required: true
                        // pattern: /^[0-9]{4}$/,
                      })}
                    />

                    {errors.confirmPassword &&
                      errors.confirmPassword.type === "required" &&
                      (is_numeric_password === "1" ? (
                        <span>Please re-enter Pin.</span>
                      ) : (
                        <span>Please re-enter Password.</span>
                      ))}

                    {errors &&
                      watch("password") &&
                      watch("confirmPassword") &&
                      watch("password") !== watch("confirmPassword") && (
                        <span className="error">
                          Please make sure the Password match.
                        </span>
                      )}
                  </div>
                </div>
                {allow_link_card_registration === "1" && (
                  <div className="loyalty-card">
                    <hr />
                    <label className="loyalty-card-label">
                      {loyalty_card_message}
                    </label>
                    <div className="loyalty-card-wrapper">
                      <div className="form-control">
                        <label>Enter Loyalty Card#</label>
                        <input
                          type="text"
                          name="loyaltyNumber"
                          id="loyaltyNumber"
                          minLength={member_card_min_length}
                          maxLength={member_card_max_length}
                          ref={register({
                            required: false,
                            pattern: /^[0-9]*$/
                          })}
                        />
                        {errors.loyaltyNumber &&
                          errors.loyaltyNumber.type === "required" && (
                            <span>Please enter your loyalty number</span>
                          )}
                        {errors.loyaltyNumber &&
                          errors.loyaltyNumber.type === "pattern" && (
                            <span>Please enter valid loyalty number</span>
                          )}
                      </div>

                      <div className="form-control">
                        <label>Re-enter Loyalty Card# *</label>
                        <input
                          type="text"
                          name="confirmLoyaltyNumber"
                          id="confirmLoyaltyNumber"
                          minLength={member_card_min_length}
                          maxLength={member_card_max_length}
                          ref={register({
                            required: watch("loyaltyNumber") ? true : false,
                            pattern: /^[0-9]*$/
                          })}
                        />
                        {errors.confirmLoyaltyNumber &&
                          errors.confirmLoyaltyNumber.type === "required" && (
                            <span>Please re-enter your Loyalty Number</span>
                          )}
                        {errors.confirmLoyaltyNumber &&
                          errors.confirmLoyaltyNumber.type === "pattern" && (
                            <span>Please enter valid loyalty number</span>
                          )}
                        {errors &&
                          watch("loyaltyNumber") &&
                          watch("confirmLoyaltyNumber") &&
                          watch("loyaltyNumber") !==
                          watch("confirmLoyaltyNumber") && (
                            <span className="error">
                              Please make sure the Loyalty Number match.
                            </span>
                          )}
                      </div>
                    </div>
                    <hr />
                  </div>
                )}
                <div className="loyalty-card">
                  {headerSection &&
                    headerSection?.Allow_IContact_Integration === "1" && (
                      <div className="form-control">
                        <h4 style={{ marginBottom: "8px" }}>
                          Stay Connected{" "}
                        </h4>
                        <p className="connected-message" style={{ marginBottom: "10px" }}>
                          Verify the type of email promotions you would like
                          to receive from {headerSection?.client_name} by
                          checking the boxes below
                        </p>
                        {icontactOptions &&
                          icontactOptions?.map((each) => (
                            <div className="check-wrapper">
                              <div key={each?.slug}>
                                <input
                                  type="checkbox"
                                  name="icontact_selected_value"
                                  id={each?.slug}
                                  value={each?.slug}
                                  ref={register({
                                    required: watch("icontact_selected_value")
                                  })}
                                />
                                <label htmlFor={each?.slug} className="signup-checkbox-wrap">
                                  {each?.title}
                                </label>
                              </div>
                            </div>
                          ))}
                      </div>
                    )}

                  <hr />
                </div>
                <div className="terms-conditions-wrapper">
                  <div className="terms-and-conditions-wrapper">
                    <input
                      type="checkbox"
                      id="termsandconditions"
                      name="termsandconditions"
                      ref={register({
                        required: true
                      })}
                    />

                    <label
                      htmlFor="termsandconditions"
                      id="termsandconditions-lable">
                      <span> &nbsp; &nbsp;I agree to the {client_name}</span>
                      <a
                        href="/custom-pages/terms-conditions"
                        rel="noreferrer"
                        target="_blank">
                        &nbsp;Terms and Conditions
                      </a>
                      <span>&nbsp;and</span>
                      <a
                        href="/custom-pages/privacy-policy"
                        rel="noreferrer"
                        target="_blank">
                        &nbsp;Privacy policy.
                      </a>
                    </label>
                  </div>
                </div>
                {errors.termsandconditions &&
                  errors.termsandconditions.type === "required" && (
                    <span style={{ fontSize: "11px", color: "#DA2327" }}>
                      Please accept Terms and Conditions to continue
                    </span>
                  )}
                <div className="re-captcha">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={your_recaptcha_site_key}
                    onChange={onChangeReCaptcha}
                  />
                  <span style={{ color: "red" }}>{captchaError}</span>
                </div>

                <div className="sign-up-button">
                  <button type="submit">Create Account</button>
                </div>
              </form>
            </div>
            <div className="auth-grid-items">
              {isShowThirdPartyLoginSection && (
                <div className="signup-option">
                  {allow_facebook_login === "1" && (
                    <div className="facebook-button">
                      <button>
                        <i className="fa-brands fa-facebook"></i>
                        <span>Continue with Facebook</span>
                      </button>
                    </div>
                  )}
                  {allow_google_login === "1" && (
                    <div className="google-button">
                      <button>
                        <i className="fa-brands fa-google"></i>
                        <span>Continue with google</span>
                      </button>
                    </div>
                  )}
                  {allow_apple_login === "1" && (
                    <div className="apple-login">
                      <button>
                        <i className="fa-brands fa-apple"></i>
                        <span>Continue with Apple</span>
                      </button>
                    </div>
                  )}
                  {allow_linkcard_login === "1" && (
                    <div className="card-registration">
                      <button>Link Card Registration</button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignupPage;
